<template>
  <a-modal
    title="新建"
    :width="680"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-form-item label="商品分类编码" :labelCol="labelCol" :wrapperCol="wrapperCol" extra="批量添加分类编码用“,”隔开。例如：010101,010201,010301">
          <a-input v-decorator="['category', {rules: [{required: true, message: '请输入'}]}]"/>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>

export default {
  name: 'CreateCouponCategoryForm',
  components: {
  },
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      map: {},
      common_warehouse_id: 0,
      commonWarehouse: [],
      warehouseCargoSpace: [],
      commodityList: []
    }
  }
}
</script>
<style>
</style>
