import request from '@/utils/request'

export function couponList (query) {
  return request({
    url: '/shop/coupon/',
    method: 'get',
    params: query
  })
}
export function createCoupon (parameter) {
  return request({
    url: '/shop/coupon/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
export function putCoupon (parameter, id) {
  return request({
    url: '/shop/coupon/' + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function deleteCoupon (id) {
  return request({
    url: '/shop/coupon/' + id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function couponGoodsList (query) {
  return request({
    url: '/shop/coupon/' + 'goods/',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: query
  })
}

export function couponGoodsCreate (data) {
  return request({
    url: '/shop/coupon/' + 'goods/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}

export function couponGoodsDelete (data) {
  return request({
    url: '/shop/coupon/' + 'goods/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}

export function couponUserList (query) {
  return request({
    url: '/shop/coupon/' + 'user/',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: query
  })
}

export function couponUserCreate (data) {
  return request({
    url: '/shop/coupon/' + 'user/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}

export function couponUserDelete (data) {
  return request({
    url: '/shop/coupon/' + 'user/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}

export function couponCategoryList (query) {
  return request({
    url: '/shop/coupon/' + 'category/',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: query
  })
}

export function couponCategoryCreate (data) {
  return request({
    url: '/shop/coupon/' + 'category/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}

export function couponCategoryDelete (data) {
  return request({
    url: '/shop/coupon/' + 'category/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}
